<template>
    <div>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            class="dashboard"
            tag="div"
        >
            <h1>
                {{ $_pagetitle }}
            </h1>
            <div :class="$style.detailInner">
                <template v-for="(data, index) in collection">
                    <template v-if="field[data.field].type !== 'file'">
                        <h2 :key="`head${index}`" :class="$style.detailHead">
                            {{ field[data.field].head }}
                        </h2>
                        <div :key="`div${index}`">
                            <template v-if="field[data.field].type === 'id'">
                                <Input
                                    :data="data"
                                    :disabled="true"
                                    :flag-validated-immediate="flagValidatedImmediate"
                                    :input="readData[data.field]"
                                    @input="input[data.field] = $event"
                                />
                            </template>
                            <template v-else>
                                <Input
                                    :data="data"
                                    :disabled="disabled"
                                    :flag-validated-immediate="flagValidatedImmediate"
                                    :input="readData[data.field]"
                                    @input="input[data.field] = $event"
                                />
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <h2 :key="`head${index}`" :class="$style.detailHead">
                            {{ field[data.field].head }}{{ fileIndex[index] + 1 }}
                        </h2>
                        <div :key="`div${index}`">
                            <InputFile
                                :data="data"
                                :disabled="disabled"
                                :flag-validated-immediate="flagValidatedImmediate"
                                :key="`file${index}`"
                                :option="file[fileIndex[index]].option"
                                @data="file[fileIndex[index]].data = $event"
                                @delete-flag="file[fileIndex[index]].deleteFlag = $event"
                                @file-name="$_setFileName(fileIndex[index], $event)"
                            />
                        </div>
                    </template>
                </template>
            </div>
            <div class="l-2-00-m--all" :class="$style.buttonArea">
                <div>
                    <Button
                        type="back"
                        @action="$_buttonBack()"
                    />
                    <Button
                        v-if="editMode !== 'detail'"
                        :disabled="invalid"
                        type="next"
                        @action="$_buttonNext()"
                    />
                </div>
                <div>
                    <Button
                        v-if="$store.getters.getManagements[name].button.delete && (editMode === 'update' || editMode === 'detail')"
                        text="削除する"
                        type="delete"
                        @action="$_buttonDelete()"
                    />
                </div>
            </div>
        </ValidationObserver>
        <Modal
            :modal="modal"
        />
    </div>
</template>

<script>
    "use strict";

    import dashboard from "@/mixins/dashboard.js";
    import detail from "@/mixins/detail.js";

    export default {
        "mixins": [
            dashboard,
            detail,
        ],
        "props": {
            "editMode": {
                "type": String,
                "required": true,
            },
            "history": {
                "type": Boolean,
                "required": false,
                "default": false,
            },
            "id": {
                "type": Array,
                "required": false,
                "default": function() {
                    return [];
                },
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        created() {
            this.$_created();
        },
    };
</script>

<style lang="scss" module>
    .buttonArea {
        margin: 72px 0 0;
    }
    .detailHead {
        margin: 40px 0 8px !important;
    }
    .detailInner {
        width: 480px;
    }
</style>
